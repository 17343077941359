
import React, { useContext, useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { ChatContext } from "../../../context/ChatContext";
import { db } from "../../../firebase";
import Message from "./Message";
import AdminList from "./AdminList";


const Messages = () => {
  const [messages, setMessages] = useState([]);
  const { data } = useContext(ChatContext);

  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unSub();
    };
  }, [data.chatId]);

  console.log(messages);

  return (
    <div className="messages border-t-2 border-b-2 my-2 py-5 h-[300px] overflow-y-auto border-red-500">
      {messages.length > 0 ? (
        messages.map((m) => (
          <Message message={m} key={m.id} />
        ))
      ) : (
        <div>
          <AdminList />
        </div>
      )}
    </div>
  );
};

export default Messages;
