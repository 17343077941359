import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import SocialMedia from "./SocialMedia";
import { Helmet } from "react-helmet";

function FindUsOn() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <Helmet>
        <title>Find Us On | {`${rpdata?.dbPrincipal?.name}`}</title>
        <meta
          name="description"
          content={`${rpdata?.dbAbout?.[0].text.substring(0, 150) + "..."}`}
        />
        <meta
          name={` ${rpdata?.dbPrincipal?.name}`}
          content={` ${rpdata?.dbPrincipal?.name}`}
        ></meta>
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <link rel="icon" href={rpdata?.dbPrincipal?.favicon} />
      </Helmet>
      <div className="bg-gray-900 opacity-95">
        <div className="w-4/5 mx-auto">
          <div className="flex items-center flex-col pt-10">
            <img
              src={rpdata?.dbPrincipal?.logo}
              alt="logo"
              className="md:w-[20%] mb-6"
            />
            {rpdata?.dbSocialMedia?.directorios?.slice(0,1).map((directorio, index) => {
              return (
                <a
                  href={directorio.url}
                  key={index}
                  className="text-white bg-[#BE1622] p-4 md:w-[500px] w-[250px] text-start my-2 rounded-lg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-link mr-2"></i>
                  {directorio.name}
                </a>
              );
            })}
            
            <a
              href={rpdata?.gmb?.link}
              className="text-white bg-[#BE1622] p-4 md:w-[500px] w-[250px] text-start my-2 rounded-lg"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-link mr-2"></i>
              Google My Business
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=13035009219"
              className="text-white bg-[#BE1622] p-4 md:w-[500px] w-[250px] text-start my-2 rounded-lg"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-link mr-2"></i>
              Whatsapp
            </a>
            {rpdata?.dbSocialMedia?.directorios?.slice(1).map((directorio, index) => {
              return (
                <a
                  href={directorio.url}
                  key={index}
                  className="text-white bg-[#BE1622] p-4 md:w-[500px] w-[250px] text-start my-2 rounded-lg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-link mr-2"></i>
                  {directorio.name}
                </a>
              );
            })}
            
            <SocialMedia />
          </div>
        </div>
      </div>
    </>
  );
}

export default FindUsOn;
