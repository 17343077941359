import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import Values from "../components/About/Values";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import BlockHomeTwo from "../components/Home/BlockHomeTwo";
import CallToActionTwo from "../components/global/CallToActionTwo";
import ServicesHome from "../components/Home/ContentServicesHome";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import HeroVideo from "../components/Home/HeroVideo";
import CounterHome from "../components/Home/CounterHome";
import VideoPromo from "../components/Home/VideoPromo";
import AboutBlock from "../components/Home/AboutBlock";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroVideo />
        <BlockHomeOne />
        <Directories />
        <CounterHome
          bgimg={`${rpdata?.gallery?.[8]}`}
          miles={rpdata?.dbPrincipal?.miles}
          location={rpdata?.dbPrincipal?.location[0].address}
        />
        <VideoPromo bgimg={`${rpdata?.videosPromo?.[0].img}`} id={rpdata?.videosPromo?.[0].link} />
        <BlockHomeTwo />
        <AboutBlock />
        <Values />
        <div className="mb-10">
        {/* <VideoPromo bgimg={`${rpdata?.gallery?.[9]}`} id="EoE_qj2Y2ys" /> */}
        </div>
        <CallToActionTwo
          SloganOne="GET YOUR FREE CONSULTATION"
          SloganTwo="To better serve our clients, we have a fully bilingual crew that is more than ready to provide you with the best customer service."
          bgimg={`${rpdata?.gallery?.[8]}`}
          phone={rpdata?.dbPrincipal?.phones[0].phone}
        />
        <div>
          <h2 className="text-center pt-[100px]">Our Recent Projects</h2>
          <div className="py-[100px] flex justify-center">
            <div className="gallery-content w-4/5 mx-auto">
              <Gallery>
                {rpdata?.stock?.slice(0, 10).length > 0
                  ? rpdata?.stock?.slice(0, 10).map((item, index) => (
                      <Item
                        key={index}
                        original={item}
                        thumbnail={item}
                        width="1024"
                        height="550"
                        padding="10px"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={item}
                            alt="Not Found"
                          />
                        )}
                      </Item>
                    ))
                  : rpdata?.gallery?.slice(0, 10).map((item, index) => (
                      <Item
                        key={index}
                        original={item}
                        thumbnail={item}
                        width="1024"
                        height="550"
                        padding="10px"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={item}
                            alt="Not Found"
                          />
                        )}
                      </Item>
                    ))}
              </Gallery>
            </div>
          </div>
        </div>
        <div className="py-20">
          <h2 className="text-center">What Our Clients Say</h2>
          <div class="elfsight-app-d1fb2c41-265c-494b-82ba-ba99916c4ab2"></div>
        </div>
        <ServicesHome />
        <FormHome />
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
