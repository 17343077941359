import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import ServicesBlocks from "../Services/ServicesBlock";
import Map from "../Contact/MapContent";
import Values from "../About/Values";

function ServicesDetail() {
  const { rpdata } = useContext(GlobalDataContext);
  const { id } = useParams();
  let classAnterior = "row"
  let classAnterioMargin = 'md:mr-[-40px]'

  return (
    <BaseLayout PageName="Services Detail">
      {rpdata?.dbServices?.map((item, index) => {
        if (item.name.replaceAll(" ", "-").toLowerCase() === id) {
          return (
            <div key={index}>
              <TransparentHeader
                bgimg={rpdata?.stock?.[1]}
                headertitle={item.name}
                Subheader={item.name}
              />
               <div className="md:w-4/5 w-[95%] mx-auto">
              {
                item.description.map((service, index) => {
                  let classes = index % 2 === 0 ? "row" : "row-reverse"
                  let nuevaClass = classAnterior === "row" ? "row-reverse" : "row"
                  classes = nuevaClass
                  classAnterior = classes;
                  const titleNone = index === 0 ? ' block' : 'hidden'

                  let classeMargin = index % 2 === 0 ? 'md:mr-[-40px]' : 'md:ml-[-40px]'
                  let nuevaClasseMargin = classAnterioMargin === "md:mr-[-40px]" ? "md:ml-[-40px]" : "md:mr-[-40px]"
                  classeMargin = nuevaClasseMargin
                  classAnterioMargin = classeMargin;

                  return (
                    <ServicesBlocks
                      key={index}
                      ServiceName={item.name}
                      TextService={service.text}
                      itemServices={service.lists.map((list, index) => {
                        return (
                          list.length > 0 ? <li key={index}><i className="fa fa-arrow-right mr-2"></i>{list}</li> : null
                        )
                      })}
                      bgimg={service.img}
                      ClassesRow={classes}
                      DisplayNone={titleNone}
                      classesMargin={classeMargin}
                    />
                  )
                })
              }
              </div>
              <Values/>
              <Map/>
            </div>
          );
        }else return console.log('Servicio no encontrado')
      })}
    </BaseLayout>
  );
}

export default ServicesDetail;
