import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";

const Message = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const formatDate = (date) => {
    const messageDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
    return messageDate.toLocaleString(); // Cambia el formato según tus preferencias
  };

  return (
    <div
      ref={ref}
      className={`flex gap-1 mb-5 ${message.senderId === currentUser.uid && "flex-row-reverse"}`}
    >
      <div className="flex">
        <img
          src={
            message.senderId === currentUser.uid
              ? currentUser.photoURL
              : data.user.photoURL
          }
          alt="avatar"
          className="w-[50px] h-[50px] rounded-full object-cover"
        />
        {/* <span>just now</span> */}
      </div>
      <div className="self-end w-[80%]">
        <p className={`text-white px-[20px] py-[10px] overflow-visible ${message.senderId === currentUser.uid ? 'bg-gray-500 rounded-br-[10px] rounded-bl-[10px] rounded-tl-[10px]' : 'bg-red-500 rounded-bl-[10px] rounded-br-[10px] rounded-tr-[10px]'}`} style={{ overflowWrap: 'break-word', maxWidth: '200px' }}>
          {message.text}
        </p>

        {message.img && <img src={message.img} alt="images" className="w-full h-full object-cover mt-1" />}
        <span className="-mt-[10px] text-gray-500 text-[10px] px-[10px]">{formatDate(message.date)}</span>
      </div>
    </div>
  );
};

export default Message;
