import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from '../global/BaseLayout'
import TransparentHeader from '../global/TransparentHeader'

function OurVideos() {
    const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Videos">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Our Videos"
          Subheader="Our Videos"
          bgimg={`${rpdata?.stock?.[1]}`}
        />
        <div>
        <h1 className="text-center text-[#FF6600] md:pt-[100px] pt-10">OUR LATEST VIDEOS</h1>
        <div className="flex w-4/5 mx-auto pb-[100px] md:flex-row flex-col">
            <iframe src="https://www.youtube.com/embed/DPUUodKDUI0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="w-full h-[250px] px-4 py-4"></iframe>
            <iframe src="https://www.youtube.com/embed/EoE_qj2Y2ys" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="w-full h-[250px] px-4 py-4"></iframe>
            <iframe src="https://www.youtube.com/embed/7WiKCZrjMb4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="w-full h-[250px] px-4 py-4"></iframe>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default OurVideos
