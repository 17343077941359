import React, { useContext, useState } from 'react';
import Sidebar from './Sidebar';
// import Chat from '../components/Chat';
import { AuthContext } from '../../../context/AuthContext';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Chat from './Chat';

const ContentChatBot = ({ setOpeFormtype, setLogeado }) => {
  const { currentUser } = useContext(AuthContext);

  const isClientRole = () => {
    return currentUser && currentUser.role === 'cliente';
  };

  const [isSidebarHidden, setIsSidebarHidden] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarHidden(prevState => !prevState);
  };

  return (
    <div className='home'>
      <div className={`container ${!isClientRole() ? 'full-width' : ''}`}>
        {
          !isClientRole() &&
          <button className={`toggleSidebarButton ${isSidebarHidden ? 'bgColor' : ''}`} onClick={toggleSidebar}>
            {isSidebarHidden ? <FaChevronRight /> : <FaChevronLeft />}
          </button>
        }

        {!isClientRole() && <Sidebar isSidebarHidden={isSidebarHidden} setIsSidebarHidden={setIsSidebarHidden} />}


        <Chat isSidebarHidden={isSidebarHidden}  setOpeFormtype={setOpeFormtype} setLogeado={setLogeado} />
      </div>
    </div>
  );
}

export default ContentChatBot