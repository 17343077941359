import React, { useContext, useState, useEffect } from "react";
import { collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";

const AdminList = () => {
  const [adminUsers, setAdminUsers] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  const [ocultarBienvenida, setOcultarBienvenida] = useState(true)


  console.log(ocultarBienvenida)

  useEffect(() => {
    if (currentUser.role === "cliente") {
      const getAdminUsers = async () => {
        try {
          const q = query(collection(db, "users"), where("role", "==", "admin"));
          const querySnapshot = await getDocs(q);
          const adminUsers = querySnapshot.docs.map((doc) => doc.data());
          setAdminUsers(adminUsers);
        } catch (error) {
          console.error("Error fetching admin users:", error);
        }
      };
      getAdminUsers();
    }
  }, [currentUser.role]);

  const handleSelectAdmin = async (adminUser) => {
    const combinedId =
      currentUser.uid > adminUser.uid
        ? currentUser.uid + adminUser.uid
        : adminUser.uid + currentUser.uid;

    try {
      const chatDocRef = doc(db, "chats", combinedId);
      const chatDocSnapshot = await getDoc(chatDocRef);

      if (!chatDocSnapshot.exists()) {
        // Crear el documento de chat si no existe
        await setDoc(chatDocRef, { messages: [] });

        // Actualizar la colección "userChats" para ambos usuarios
        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId]: {
            userInfo: {
              uid: adminUser.uid,
              displayName: adminUser.displayName,
              photoURL: adminUser.photoURL,
            },
            date: serverTimestamp(),
          },
        });

        await updateDoc(doc(db, "userChats", adminUser.uid), {
          [combinedId]: {
            userInfo: {
              uid: currentUser.uid,
              displayName: currentUser.displayName,
              photoURL: currentUser.photoURL,
            },
            date: serverTimestamp(),
          },
        });

        // Despachar la acción para establecer el chatId en el contexto
        dispatch({ type: "CHANGE_USER", payload: adminUser });
      } else {
        // Si el chat ya existe, establecer el chatId en el contexto
        dispatch({ type: "CHANGE_USER", payload: adminUser });
      }
    } catch (error) {
      console.error("Error creating or selecting chat:", error);
    }
  };

  return (
    <div className="adminList">
      {adminUsers.length > 0 ? (
        adminUsers.map((adminUser) => (
          <div>
            {
              ocultarBienvenida ? (
                <>
                  <div className="text-center">
                    <h2>Welcome</h2>
                    <p className="capitalize -mt-5 mb-5">please contact</p>
                  </div>
                  {
                    currentUser.role === 'cliente' && (
                      <div
                        className="flex flex-col items-center gap-5 cursor-pointer"
                        key={adminUser.uid}
                        onClick={() => {
                          handleSelectAdmin(adminUser)
                          setOcultarBienvenida(false)
                        }}
                      >
                        <img src={adminUser.photoURL} alt="avatars" className="w-[50px] h-[50px] object-cover rounded-full" />
                        <div className="font-bold flex flex-col items-center justify-center">
                          <span>{adminUser.displayName}</span>
                          <span className="capitalize text-red-600 hover:text-green-600 transition-all ease-in-out duration-300">start a conversation</span>
                        </div>
                      </div>
                    )
                  }
                </>
              )
                : <p className="text-white px-[20px] py-[10px] bg-red-500 rounded-bl-[10px] rounded-br-[10px] rounded-tr-[10px] capitalize">welcome, my name is Jose Luis Cardenas. how can I help you? </p>
            }



          </div>
        ))
      ) : (
        <p className="capitalize">There is no conversation selected to start a chat.</p>
      )}
    </div>
  );
};

export default AdminList;
