import React, { useContext } from 'react'
import { signOut } from "firebase/auth"
import { auth } from '../../../firebase'
import { AuthContext } from '../../../context/AuthContext'
import { FaPowerOff } from 'react-icons/fa'

const Navbar = ({ setOpeFormtype, setLogeado }) => {
  const { currentUser } = useContext(AuthContext)


  const handleSigOut = () => {
    setLogeado(false)
    signOut(auth)
    // Eliminar información de autenticación de localStorage
    localStorage.removeItem('auth');
    setOpeFormtype('login')
     // Recargar la página
     window.location.reload();
  }

  return (
    <div className={` ${currentUser.role === 'admin' ? 'navbarAdmin' : 'navbarCliente'}`}>
      <div className="flex justify-between items-center gap-3">
        <div className='flex items-center gap-3'>
          <img src={currentUser.photoURL} alt="avatars" className='w-[50px] h-[50px] object-cover rounded-full' />
          <div>
            <h4 className='text-[20px] font-bold'>{currentUser.displayName}</h4>
            <p className='-mt-5 text-[14px] '>{currentUser.role}</p>
          </div>
        </div>
        <button onClick={handleSigOut}>
          <span>
            <FaPowerOff />
          </span>
        </button>
      </div>
    </div>
  )
}

export default Navbar