import React from 'react'
import Form from "../Contact/Form"

function FormHome() {
  return (
    <>
      <div className='w-full flex justify-center'>
        <div className='max-w-2xl py-14 md:px-2 px-4'>
            <h2 className='text-center'>Get In Touch!</h2>
            <div className='border-2 border-gray border-opacity-1 shadow-lg p-4'>
            <Form/>
            </div>
        </div>
      </div>
    </>
  )
}

export default FormHome
