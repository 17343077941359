import React from 'react'
import { Link } from 'react-router-dom'

export const ButtonContent = ({ btnName, btnStyle, bRadius }) => {
    return (
        btnName ?
            <a
                className={`
                boton
                my-3
                ${btnStyle}
                px-6
                py-4
                w-52
                rounded-${bRadius}
            `}
                href={`tel:+1${btnName}`}
            >

                <span>
                    {btnName}
                </span>
            </a>
            :
            <Link
                className={`
                boton
                my-3
                ${btnStyle}
                px-6
                py-4
                w-52
                rounded-${bRadius}
            `}
                to="/contact"
            >

                <span>
                    free estimate
                </span>
            </Link>
    )
}
