import React from "react";

const TransparentHeader = ({ headertitle, bgimg }) => {
  return (
    <div
      className="breadcrumb-area bg-overlay"
      style={{ backgroundImage: `url("${bgimg}")` }}
    >
      <div className="px-40">
          <h1 className="text-white text-start">{headertitle}</h1>
          <div className="md:w-[5%] w-[30%] h-0.5 bg-white my-2"></div>
        </div>
    </div>
  );
};

export default TransparentHeader;
