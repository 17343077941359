import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import SocialMedia from "../global/SocialMedia";
import Form from "./Form";

function ContactInfo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="w-full flex justify-center">
        <div className="flex w-[1100px] py-24 md:flex-row flex-col text-center md:text-start">
          <div className="md:w-[50%] w-full md:px-14 px-2">
            <h2>Get In Touch!</h2>
            <Form />
          </div>
          <div className="md:w-[50%] w-full md:px-14 px-2">
            <h2>Contact Info</h2>
            <div className="pt-6">
              <h5>Location</h5>
              <h6>{rpdata?.dbPrincipal?.location?.[0].address}</h6>
            </div>
            <div className="pt-6">
              <h5>Phone</h5>
              <h6>
                {rpdata?.dbPrincipal?.phones.map((item, index) => {
                  return (
                    <a href={`tel:+1${item.phone}`} key={index}>
                      <h6>{item.phone}</h6>
                    </a>
                  );
                })}
              </h6>
            </div >
            <div className="pt-6">
              <h5>{rpdata?.dbPrincipal?.workdays?.[0].day}</h5>
              <h6>{rpdata?.dbPrincipal?.workHours?.[0].hour}</h6>
            </div>
            <SocialMedia />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactInfo;
