import React, { useContext, useEffect } from "react";
// import Messages from "./Messages";
// import Input from "./Input";
import { ChatContext } from "../../../context/ChatContext";
import Navbar from "./Navbar";
import { AuthContext } from "../../../context/AuthContext";
import Messages from "./Messages";
import Input from "./Input";

const Chat = ({ isSidebarHidden, setOpeFormtype, setLogeado }) => {

  const { currentUser } = useContext(AuthContext);

  const { data, setChatId } = useContext(ChatContext);

  useEffect(() => {
    // Si el usuario actual tiene el rol "cliente" y no hay un chatId establecido,
    // y data.adminUser no es undefined, entonces establecer el chatId para que apunte a la conversación con el usuario administrador.
    if (data.user?.role === "cliente" && !data.chatId && data.adminUser) {
      setChatId(data.adminUser.uid); // Asegurarse de que data.adminUser no sea undefined antes de acceder a su propiedad uid
      console.log('data:', data.adminUser.uid)

    }
  }, [data.user, data.chatId, data.adminUser, setChatId]);



  return (
    <div className={`chat ${isSidebarHidden ? 'block' : 'hidden'} ${currentUser.role === 'cliente' && 'widtContnet'}`}>
      <div className="chatInfo">
        <div className="navarChatInfo">
          <Navbar setOpeFormtype={setOpeFormtype} setLogeado={setLogeado}/>
        </div>
        <div className={`${currentUser.role === 'admin' && isSidebarHidden ? 'textInfo' : 'textInfoFlex'}`}>
          <span>
            Writes To:{' '}
          </span>
          <span>
            {data.user?.displayName}
          </span>
        </div>
      </div>
      <Messages />
      <Input />
    </div>
  );
};

export default Chat;
