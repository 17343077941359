import React, { useState, useEffect } from 'react';
import { BsFillChatRightTextFill } from 'react-icons/bs';
import Login from './Login';
import Register from './Register';
import ContentChatBot from './components/ContentChatBot';

const ChatBotBtn = () => {
  const [opneModalChat, setOpneModalChat] = useState(false);
  const [logeado, setLogeado] = useState(false);

  // Verificar Local Storage al cargar el componente
  useEffect(() => {
    const storedAuth = JSON.parse(localStorage.getItem('auth'));
    if (storedAuth && storedAuth.logeado) {
      setLogeado(true);
    }
  }, []);

  const [opeFormtype, setOpeFormtype] = useState('login');

  const handleOpenModalChat = () => {
    setOpneModalChat(!opneModalChat);
  };

  return (
    <div className='fixed z-50 right-5 bottom-5'>
      <div
        className='cursor-pointer bg-red-600 w-[50px] h-[50px] rounded-full flex justify-center items-center text-white text-[20px]'
        onClick={handleOpenModalChat}
      >
        <BsFillChatRightTextFill />
      </div>
      {opneModalChat && (
        <div className='absolute z-50 p-5 bottom-14 md:bottom-10 -right-2 md:right-14 rounded-lg bg-white shadow-lg w-[350px] h-[500px] text-black '>
          {logeado ? (
            <ContentChatBot setOpeFormtype={setOpeFormtype} setLogeado={setLogeado} />
          ) : (
            <>
              {opeFormtype === 'login' ? (
                <Login setOpeFormtype={setOpeFormtype} setLogeado={setLogeado} />
              ) : (
                <Register setOpeFormtype={setOpeFormtype}  setLogeado={setLogeado}/>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatBotBtn;
