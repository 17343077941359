import React, { useContext } from "react";
// import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
// import { ButtonContent } from "../global/boton/ButtonContent";

function ServicesHome({ bgImg, serviceName, serviceText }) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="w-full">
      <div className="max-w-7xl text-center">
        <div className="border-2 border-white rounded-3xl py-8 px-4 h-[400px] flex flex-col justify-center items-center">
          <h4>{serviceName}</h4>
          <div className={`md:w-[20%] w-[30%] h-0.5 bg-white my-2`}></div>
          <p>{serviceText}</p>
          <a
            className={` boton my-3 tree px-6 py-4 w-52 rounded-sm bg-white text-gray-700 `}
            href={`tel:+1${rpdata?.dbPrincipal?.phones[0].phone}`}
          >
            <span>Call Us Now</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ServicesHome;
