import React, { useContext, useState } from "react";
import {
  collection,
  // query,
  // where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import Chats from "./Chats";


const Search = ({ setIsSidebarHidden }) => {
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [err, setErr] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);


  const handleSearch = async () => {
    const usersCollection = collection(db, "users");

    try {
      const querySnapshot = await getDocs(usersCollection);
      const matchingUsers = [];

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.displayName.toLowerCase().includes(username.toLowerCase())) {
          matchingUsers.push(userData);
        }
      });

      setUser(matchingUsers);
      setErr(matchingUsers.length === 0);
    } catch (err) {
      setErr(true);
    }
  };

  const handleKey = (e) => {
    e.code === "Enter" && handleSearch();
  };

  const handleInputChange = (e) => {
    setUsername(e.target.value);
    setUser(null);
  };

  const handleSelect = async (u) => {
    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });
        //create user chats
        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
      dispatch({ type: "CHANGE_USER", payload: u });

      setIsSidebarHidden(true)

    } catch (err) { }

    setUser(null);
    setUsername("")
  };

  return (
    <div className="search">
      <div className="my-3 border h-[50px] flex items-center px-3 rounded-lg">
        <input
          type="text"
          placeholder="Find a user"
          onKeyDown={handleKey}
          onChange={handleInputChange}
          value={username}
        />
      </div>

      <div className="border rounded-lg h-[300px] overflow-y-auto">
        {err && user && <div className="mb-5 px-5"><span className="capitalize">customer not found!</span></div>}
        {username && user && user.map((resultUser) => (
          <div
            key={resultUser.uid}
            className="flex items-center gap-3 p-3 cursor-pointer transition-all ease-in-out  hover:bg-[#2f2d52] rounded-lg"
            onClick={() => handleSelect(resultUser)}
          >
            <img
              src={resultUser.photoURL}
              alt="avatars"
              className="w-[50px] h-[50px] rounded-full object-cover"
            />
            <div className="userChatInfo">
              <span>{resultUser.displayName}</span>
            </div>
          </div>
        ))}
        {
          !username &&
          <Chats setIsSidebarHidden={setIsSidebarHidden} />
        }
      </div>



    </div>
  );
};

export default Search;