import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

// Función para obtener el rol del usuario desde Firestore
const getUserRole = async (uid) => {
  try {
    const userDocRef = doc(db, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      return userData.role; // Retorna el valor del campo "role" del documento del usuario
    } else {
      // Si no se encuentra el documento del usuario, retorna null o un valor predeterminado
      return null;
    }
  } catch (error) {
    console.error("Error al obtener el rol del usuario:", error);
    return null;
  }
};

export default getUserRole;
