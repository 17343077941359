import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="mx-auto flex justify-center items-center text-center">
          <a href={rpdata?.gmb?.link} target="_blank" rel="noopener noreferrer">
            <img
              src={rpdata?.gmb?.img}
              alt="Not Found"
              className="w-[300px] object-cover"
            ></img>
          </a>
        </div>
      <div className="px-4 py-8">
        <h2 className="text-center">Find Us</h2>
        <div className="w-4/5 mx-auto flex justify-center">
          <div className="grid md:grid-cols-5 col-auto grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]">
            {rpdata?.dbSocialMedia?.directorios.map((item, index) => {
              return (
                <div className="text-center content-directorios" key={index}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <img src={item.image} alt="Not found"></img>
                  </a>
                </div>
              );
            })}
            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
              return (
                <div className="text-center content-directorios" key={index}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <img src={item.image} alt="Not Found"></img>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Directories;
