import React, { useState } from "react";
import Add from "../../img/addAvatar.png";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";

const Register = ({ setOpeFormtype, setLogeado }) => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();


  const [avatarUrl, setAvatarUrl] = useState("");

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setAvatarUrl(url);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const displayName = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    const file = e.target[3].files[0];


    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      //Create a unique image name
      const date = new Date().getTime();
      const storageRef = ref(storage, `${displayName + date}`);

      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          try {
            //Update profile
            await updateProfile(res.user, {
              displayName,
              photoURL: downloadURL,
            });
            //create user on firestore
            await setDoc(doc(db, "users", res.user.uid), {
              uid: res.user.uid,
              displayName,
              email,
              photoURL: downloadURL,
              role: "cliente",
            });

            //create empty user chats on firestore
            await setDoc(doc(db, "userChats", res.user.uid), {});
            // navigate("/");
            setLogeado(true)
            localStorage.setItem('auth', JSON.stringify({ logeado: true }));

            setOpeFormtype('')

            // Recargar la página
            window.location.reload();

          } catch (err) {
            console.log(err);
            setErr(true);
            setLoading(false);
          }
        });
      });
    } catch (err) {
      setErr(true);
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-full h-full flex flex-col justify-center items-center gap-2">
        {/* <span className="logo">Inter Chat</span> */}
        <h3 className="text-[30px]">Register</h3>
        <form onSubmit={handleSubmit} className="w-full h-full flex flex-col justify-center items-center gap-3">
          <input required type="text" placeholder="display name" className="w-full h-[50px] rounded-lg px-3 shadow-lg" />
          <input required type="email" placeholder="email" className="w-full h-[50px] rounded-lg px-3 shadow-lg" />
          <input required type="password" placeholder="password" className="w-full h-[50px] rounded-lg px-3 shadow-lg" />
          <input required style={{ display: "none" }} type="file" id="file" onChange={handleAvatarChange} />
          <label htmlFor="file" className="w-full flex items-center gap-5 border-2 border-dotted rounded-lg cursor-pointer">
            <img src={`${avatarUrl || Add}`} alt="images" className="w-[70px] h-[70px] object-cover rounded-lg" />
            <span>Add an avatar</span>
          </label>
          <button disabled={loading} className="w-full bg-red-600 p-3 text-white font-bold rounded-lg">Sign up</button>
          {loading && "Uploading and compressing the image please wait..."}
          {err && <span>Something went wrong</span>}
        </form>
        <p>
          You do have an account? <button onClick={() => setOpeFormtype('login')} className="text-red-600">Login</button>
        </p>
      </div>
    </div>
  );
};

export default Register;
