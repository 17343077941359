import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ChatContext } from "../../../context/ChatContext";
import { db } from "../../../firebase";

const Chats = ({ setIsSidebarHidden }) => {
  const [chats, setChats] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        const chatsData = doc.data();
        // Filtrar chats para excluir los usuarios eliminados
        const filteredChats = Object.entries(chatsData)
          .filter(([chatId, chatInfo]) => !chatInfo.userInfo.deleted)
          .sort((a, b) => b[1].date - a[1].date);

        setChats(filteredChats);
      });

      return () => {
        unsub();
      };
    };

    currentUser.uid && getChats();
  }, [currentUser.uid]);

  const handleSelect = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
    setIsSidebarHidden(true)
  };



  return (
    <div className="chats">
      {
        chats.map((chat) => (
          <div
            className="flex items-center gap-3 p-3 cursor-pointer transition-all ease-in-out  hover:bg-[#f95a65] rounded-lg"
            key={chat[0]}
            onClick={() => handleSelect(chat[1].userInfo)}
          >
            <img src={chat[1].userInfo.photoURL} alt="images" className="w-[50px] h-[50px] rounded-full object-cover" />
            <div className="font-bold">
              <span>{chat[1].userInfo.displayName}</span>
              <p>{chat[1].lastMessage?.length && chat[1].lastMessage?.text}</p>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default Chats;

