import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC4mByXJaw22gJOeOYL0pSwmn1diML99Bk",
  authDomain: "chatapp-v2-25f89.firebaseapp.com",
  projectId: "chatapp-v2-25f89",
  storageBucket: "chatapp-v2-25f89.appspot.com",
  messagingSenderId: "1087510222581",
  appId: "1:1087510222581:web:39fea990f6db924cfe97bc"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
