import React, { useContext } from 'react';
import { GlobalDataContext } from "../../context/context";

function Values() {
    const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="container mx-auto md:w-full py-16 flex md:flex-row flex-col justify-center items-center">
        <div className="bg-white w-80 text-center m-4 cursor-pointer ">
          <div className=" flex justify-center">
          <i className="fa fa-medal text-[50px] text-[#BE1622]"></i>
          </div>
          <div className="p-4">
            <h2 className="text-2xl uppercase py-4">Mission</h2>
            <p>
                {rpdata?.dbValues?.[0].description}
            </p>
          </div>
        </div>
        <div className="bg-white w-80 text-center m-4 cursor-pointer ">
          <div className=" flex justify-center">
          <i className="fa fa-road text-[50px] text-[#BE1622]"></i>
          </div>
          <div className="p-4">
            <h2 className="text-2xl uppercase py-4">Vision</h2>
            <p>
            {rpdata?.dbValues?.[1].description}
            </p>
          </div>
        </div>
        <div className="bg-white w-80 text-center m-4 cursor-pointer ">
          <div className=" flex justify-center">
          <i className="fa fa-hands text-[50px] text-[#BE1622]"></i>
          </div>
          <div className="p-4">
            <h2 className="text-2xl uppercase py-4">Why Choose Us</h2>
            <p>
            {rpdata?.dbValues?.[2].description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Values;
