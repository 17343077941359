import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";

function OurReviews() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Videos">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Our Reviews"
          Subheader="Our Reviews"
          bgimg={`${rpdata?.stock?.[1]}`}
        />
        <div className="py-20">
          <h1 className="text-center text-[#BE1622]">WHAT OUR CLIENTS SAY</h1>
          <div class="elfsight-app-d1fb2c41-265c-494b-82ba-ba99916c4ab2"></div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default OurReviews;
